import Repository from "../Repository"

const resource = "workflows"
const searchDefaultParams = {
  page: 1,
  limit: -1,
  sort: "",
  order: ""
}

export default {
  getWorkflows({
    page,
    limit,
    sort,
    order,
    searchParams
  } = searchDefaultParams) {
    return Repository.get(`${resource}`, {
      params: {
        page,
        limit,
        sort,
        order,
        ...searchParams
      }
    })
  },
  postWorkflowAssign(
    { ids, assigned_user_id } = { ids: [], assigned_user_id: null }
  ) {
    return Repository.post(`${resource}/assign`, {
      ids,
      assigned_user_id
    })
      .then(res => {
        return res
      })
      .catch(() => {
        return false
      })
  }
}
