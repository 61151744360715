import Repository from "../Repository"

const resource = "users"
const searchDefaultParams = {
  page: 1,
  limit: -1,
  sort: "",
  order: ""
}

export default {
  getUsers({ page, limit, sort, order } = searchDefaultParams) {
    return Repository.get(`${resource}`, {
      params: {
        page,
        limit,
        sort,
        order
      }
    })
  }
}
