import TurbolinksAdapter from "vue-turbolinks"
import Vue from "vue"
import App from "../components/pages/AppWorkflowIndex.vue"

Vue.use(TurbolinksAdapter)
Vue.config.productionTip = false

document.addEventListener("turbolinks:load", () => {
  const el = document.getElementById("js-workflow-index")

  if (el) {
    new Vue({
      render(h) {
        return h(App)
      }
    }).$mount(el)
  }
})
