<template>
  <div>
    <b-row align-v="center">
      <b-col md="auto">
        <b-pagination
          v-model="currentPageLocal"
          :total-rows="totalRows"
          :per-page="perPage"
          class="mb-0"
          :class="pagerClass"
        >
        </b-pagination>
      </b-col>
      <b-col md="auto">
        全 {{ totalRows }}件中 {{ pageRangeFirst }}件 〜
        {{ pageRangeLast }}件を表示
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 0
    },
    totalRows: {
      type: Number,
      default: 0
    },
    pagerClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    pageRangeFirst() {
      if (this.perPage && this.currentPage) {
        return this.perPage * (this.currentPage - 1) + 1
      }
      return 0
    },
    pageRangeLast() {
      if (this.perPage && this.currentPage && this.totalRows) {
        let diff = this.perPage * this.currentPage - this.totalRows

        if (diff < 0) return this.perPage * this.currentPage
        return this.totalRows
      } else {
        return 0
      }
    },
    currentPageLocal: {
      get() {
        return this.currentPage
      },
      set(newVal) {
        this.$emit("input", newVal)
      }
    }
  }
}
</script>
