import axios from "axios"

const baseDomain = process.env.BASE_DOMAIN
const baseURL = `${baseDomain}`

const tokenElement = document.querySelector("meta[name=csrf-token]")

export default axios.create({
  baseURL,
  headers: {
    common: {
      "X-CSRF-Token": tokenElement ? tokenElement.content : null,
      "X-Requested-With": "XMLHttpRequest"
    }
  }
})
