<template>
  <b-form-select v-model="perPageLocal" size="100">
    <option disabled>表示件数</option>
    <option :value="op.value" v-for="op in perPageOptions" :key="op.value">
      {{ op.text }}
    </option>
  </b-form-select>
</template>

<script>
export default {
  props: {
    perPage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      perPageOptions: [
        { value: 10, text: "10件" },
        { value: 20, text: "20件" },
        { value: 50, text: "50件" },
        { value: 100, text: "100件" }
      ]
    }
  },
  computed: {
    perPageLocal: {
      get() {
        return this.perPage
      },
      set(newVal) {
        this.$emit("input", newVal)
      }
    }
  }
}
</script>
