<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          表示条件絞り込み
        </h4>
      </b-card-header>

      <b-card-body>
        <b-row class="mb-2">
          <b-col>
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="NaviID"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchParams.navi_id"
                  placeholder="検索したいNavIDを入力"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="ワークフローカテゴリ"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-select
                  v-model="searchParams.category"
                  :options="categoryOptions"
                >
                  <option value="">
                    未選択
                  </option>
                </b-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="物件名"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchParams.realestate_name"
                  placeholder="検索したい物件名を入力"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="ワークフロー種別"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-select
                  v-model="searchParams.flow_type"
                  :options="flowTypeOptions"
                >
                  <option value="">
                    未選択
                  </option>
                </b-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col md="6">
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="最終更新日"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchParams.updated_at_from"
                  class="js-datepicker"
                  placeholder="開始"
                />
                <span class="p-2">
                  〜
                </span>
                <b-form-input
                  v-model="searchParams.updated_at_to"
                  class="js-datepicker"
                  placeholder="終了"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="状態"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-select
                  v-model="searchParams.status"
                  :options="statusOptions"
                >
                  <option value="">
                    未選択
                  </option>
                </b-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col md="6">
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="次回対応予定日"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchParams.next_action_date_from"
                  class="js-datepicker"
                  placeholder="開始"
                />
                <span class="p-2">
                  〜
                </span>
                <b-form-input
                  v-model="searchParams.next_action_date_to"
                  class="js-datepicker"
                  placeholder="終了"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="入居者様名"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchParams.guest_name"
                  placeholder="検索したい入居者様名を入力"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col md="5">
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="要注意フラグ"
              class="mb-1"
              label-class="font-weight-bold"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div class="form-check form-check-inline mt-2">
                <b-form-checkbox
                  v-model="searchParams.urgent_payment"
                  true-value="true"
                  false-value="no"
                >
                  至急入金
                </b-form-checkbox>
              </div>
              <div class="form-check form-check-inline">
                <b-form-checkbox
                  v-model="searchParams.urgent_return"
                  value="true"
                  false-value="no"
                >
                  至急返送
                </b-form-checkbox>
              </div>
              <div class="form-check form-check-inline">
                <b-form-checkbox
                  v-model="searchParams.attention"
                  value="true"
                  false-value="no"
                >
                  注意
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="footer" class="text-right">
          <b-button @click.prevent="clearSearchParams">
            検索条件をクリア
          </b-button>
          <b-button variant="primary" @click.prevent="searchWorkflows">
            入力した条件で検索
          </b-button>
        </div>
      </b-card-body>
    </b-card>

    <b-card>
      <b-row class="mb-4" align-h="between">
        <b-col cols="auto">
          <b-form inline>
            <b-button
              class="mr-2"
              @click.prevent="selectAllPerPage('workflowTable')"
            >
              全選択
            </b-button>

            <b-button
              class="mr-2"
              :disabled="!isSelected"
              @click.prevent="clearSelectedItems()"
            >
              選択解除
            </b-button>

            <b-input-group class="mr-2">
              <b-form-select
                v-model="selectedAssignedUser"
                :disabled="!isSelected"
              >
                <option :value="null" disabled>
                  担当者を選択
                </option>
                <option v-for="user in users" :key="user.id" :value="user">
                  {{ user.name }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  v-b-modal:assign-modal
                  variant="primary"
                  :disabled="!isSelected || !selectedAssignedUser"
                >
                  選択中のワークフローに担当者を割り当てる
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>

        <b-col cols="auto">
          <b-form inline>
            <label class="mr-2">
              表示件数
            </label>
            <app-pager-select v-model="perPage" :per-page="perPage" />
          </b-form>
        </b-col>
      </b-row>

      <b-table
        id="workflow-table"
        ref="workflowTable"
        class="text-nowrap"
        :items="workflows"
        :fields="workflowFields"
        thead-class="text-nowrap bg-dark"
        responsive
        selectable
        :busy="isBusy"
        @row-selected="rowSelected"
      >
        <template v-slot:cell(navi_id)="row">
          <a :href="`/workflows/${row.item.id}`">
            {{ row.item.navi_id }}
          </a>
        </template>

        <template v-slot:cell(workflow_histories)="row">
          <a :href="`/workflows/${row.item.id}`" target="_blank">
            <i class="fas fa-link" />
          </a>
        </template>

        <template v-slot:cell(category)="row">
          <span
            class="crest crest-pill"
            :class="{
              'crest-blue': row.item.category === '転貸',
              'crest-pink': row.item.category === '保険',
              'crest-green': row.item.category === '駐車'
            }"
            >{{ row.item.category }}
          </span>
          <span
            class="crest crest-inverse"
            :class="{
              'crest-blood': row.item.status === '進行中',
              'crest-ash': row.item.status === '完了'
            }"
            >{{ row.item.flow_type }}
          </span>
        </template>
        <template v-slot:cell(urgent_payment)="row">
          <span
            class="crest"
            :class="{
              'crest-blood': row.item.urgent_payment === true,
              'crest-ash': row.item.urgent_payment === false
            }"
            >入金
          </span>
          <span
            class="crest"
            :class="{
              'crest-blood': row.item.urgent_return === true,
              'crest-ash': row.item.urgent_return === false
            }"
            >返送
          </span>
          <span
            class="crest"
            :class="{
              'crest-blood': row.item.attention === true,
              'crest-ash': row.item.attention === false
            }"
            >注意
          </span>
        </template>
      </b-table>

      <b-row align-h="between">
        <b-col md="auto">
          <app-pager
            v-model="currentPage"
            :current-page="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            class="mb-3"
            @input="fetchWorkflows"
          />
        </b-col>
        <b-col md="auto">
          <b-form inline>
            <label class="mr-2">表示件数</label>
            <app-pager-select v-model="perPage" :per-page="perPage" />
          </b-form>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="assign-modal"
      title="一括担当者更新"
      @ok="postWorkflowAssign()"
    >
      <div class="my-4">
        <p>
          以下ワークフロー
        </p>
        <ul>
          <li v-for="w in selectedWorkflows" :key="w.id">
            案件ID：{{ w.sublease_id }} {{ w.flow_type }}
            {{ w.realestate_name }}
          </li>
        </ul>
        <p>
          の担当者を
          <b>{{ selectedAssignedUser ? selectedAssignedUser.name : "" }}</b>
          に一括で設定します。よろしいですか？
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue"
import BootstrapVue from "bootstrap-vue"
import { RepositoryFactory } from "../../repositories/RepositoryFactory"
import Datepicker from "../../common/datepicker.js"
import AppPagerSelect from "../modules/AppPagerSelect"
import AppPager from "../modules/AppPager"

Vue.use(BootstrapVue)

const workflowRepository = RepositoryFactory.get("workflow")
const userRepository = RepositoryFactory.get("user")
const constantRepository = RepositoryFactory.get("constant")

const workflowFields = [
  { key: "navi_id", label: "NaviID" },
  { key: "realestate_name", label: "物件名" },
  { key: "guest_name", label: "入居者様名" },
  { key: "category", label: "ワークフロー" },
  { key: "urgent_payment", label: "要注意フラグ" },
  { key: "assigned_user", label: "担当者" },
  { key: "next_task", label: "次回TODO" },
  { key: "updated_at", label: "最終更新日" },
  { key: "next_action_date", label: "次回対応予定日" }
]

export default {
  components: {
    AppPagerSelect,
    AppPager
  },
  data() {
    return {
      isBusy: false,
      searchParams: {
        realestate_name: "",
        sublease_id: "",
        navi_id: "",
        category: "",
        flow_type: "",
        status: "",
        updated_at_from: "",
        updated_at_to: "",
        next_action_date_from: "",
        next_action_date_to: "",
        guest_name: "",
        urgent_payment: "",
        urgent_return: "",
        attention: ""
      },
      constants: null,
      users: null,
      workflowFields: workflowFields,
      workflows: null,
      selectedWorkflows: [],
      selectedAssignedUser: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      isActive: true
    }
  },
  computed: {
    isSelected() {
      return Boolean(this.selectedWorkflows.length)
    },
    categoryOptions() {
      return this.constants ? this.constants.workflow.category : []
    },
    flowTypeOptions() {
      return this.constants ? this.constants.workflow.flow_type : []
    },
    statusOptions() {
      return this.constants ? this.constants.workflow.status : []
    }
  },
  watch: {
    currentPage() {
      this.fetchWorkflows()
    },
    perPage() {
      this.fetchWorkflows()
    }
  },
  created() {
    this.fetchUsers()

    constantRepository.getConstants().then(res => {
      this.constants = res.data
    })
  },
  mounted() {
    // turbolinks:load の時点でVueの読み込みが終わっていないため、datepickerを再度適応する
    Datepicker.setDatepicker()

    // 一旦デフォルト値を外して検証します。fixしたら削除します
    // this.searchParams.next_action_date_from = $dayjs().format("YYYY/MM/DD")
    // this.searchParams.next_action_date_to = $dayjs()
    //   .add(7, "day")
    //   .format("YYYY/MM/DD")

    this.searchWorkflows()
  },
  methods: {
    async fetchWorkflows() {
      this.isBusy = true

      return await workflowRepository
        .getWorkflows({
          limit: this.perPage,
          page: this.currentPage,
          searchParams: this.searchParams
        })
        .then(res => {
          this.workflows = res.data.items
          this.totalRows = res.data.total_entries
        })
        .finally(() => {
          this.isBusy = false
        })
    },
    fetchUsers() {
      userRepository.getUsers().then(res => {
        this.users = res.data
      })
    },
    searchWorkflows() {
      this.currentPage = 1
      this.fetchWorkflows()
    },
    async clearSearchParams() {
      for (let prop in this.searchParams) {
        this.searchParams[prop] = ""
      }

      await this.fetchWorkflows()
    },
    async postWorkflowAssign() {
      const ids = this.selectedWorkflows.map(e => {
        return e.id
      })

      workflowRepository
        .postWorkflowAssign({
          ids: ids,
          assigned_user_id: this.selectedAssignedUser.id
        })
        .then(res => {
          if (res) {
            this.fetchWorkflows()
          }
        })
    },
    rowSelected(items) {
      this.selectedWorkflows = items
    },
    clearSelectedItems() {
      this.$refs.workflowTable.clearSelected()
    },
    // TODO: mixinに切り出す
    selectAllPerPage(refs_key) {
      let arr = new Array(this.$refs[refs_key].paginatedItems.length)
      this.$refs[refs_key].selectedRows = arr.fill(true)
    }
  }
}
</script>

<style lang="scss">
/* Busy table styling */
table.b-table[aria-busy="true"] {
  opacity: 0.6;
}
</style>
