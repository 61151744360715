require("bootstrap-datepicker")

$.fn.datepicker.dates["ja"] = {
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  daysShort: ["日", "月", "火", "水", "木", "金", "土"],
  daysMin: ["日", "月", "火", "水", "木", "金", "土"],
  months: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月"
  ],
  monthsShort: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月"
  ],
  today: "本日",
  clear: "クリア",
  format: "yyyy/mm/dd",
  titleFormat: "yyyy MM" /* Leverages same syntax as 'format' */,
  weekStart: 0
}

function setDatepicker() {
  $(".js-datepicker")
    .datepicker({
      format: "yyyy/mm/dd",
      language: "ja",
      autoclose: true,
      endDate: "2100/01/01",
      startDate: "1900/01/01",
      immediateUpdates: true
    })
    .on("changeDate", function() {
      $(this)[0].dispatchEvent(new Event("input"))
    })
}

function setMonthpicker() {
  $(".js-monthpicker")
    .datepicker({
      format: "yyyy/mm",
      language: "ja",
      autoclose: true,
      minViewMode: "months"
    })
    .on("changeDate", function() {
      $(this)[0].dispatchEvent(new Event("input"))
    })
}

function setMultiMonthpicker() {
  // 読み込み時に選択されている値を保存する
  let checkObj = {}
  $(".js-multi-monthpicker").one("click", function() {
    let c = 1 //カウント用
    const array = $(this)
      .val()
      .split(",") // 値を配列化

    while (c < 13) {
      checkObj[c] = true
      c++
    }

    for (const i of array) {
      checkObj[i] = false
    }
  })

  $(".js-multi-monthpicker")
    .datepicker({
      title: "指定月",
      format: "m",
      language: "ja",
      autoclose: false,
      multidate: true,
      minViewMode: "months",
      maxViewMode: 1
    })
    .on("changeDate", function(e) {
      const elmVal = e.date.getMonth() + 1 //クリックされた値

      for (let key in checkObj) {
        // 読み込み時に値がある場合の重複を削除する
        if (elmVal == key && !checkObj[key]) {
          const array = e.target.value.split(",")
          if (0 <= e.target.value.indexOf(elmVal)) {
            e.target.value = array.filter(item => {
              return item !== key
            })
          }
        }

        // 判定入れ替え
        if (elmVal == key && !checkObj[key]) {
          checkObj[key] = true
        } else if (elmVal == key && checkObj[key]) {
          checkObj[key] = false
        }
      }
      $(".js-multi-monthpicker").datepicker("update")
    })
}

document.addEventListener("turbolinks:load", () => {
  setDatepicker()
  setMonthpicker()
  setMultiMonthpicker()
})

$(document).on("click", ".js-add-other-monthly", function() {
  // [支払タブ] 月額支払で要素が追加された時に発火
  setMultiMonthpicker()
})

$(document).on("click", ".js-add-select-monthly", function() {
  // [支払タブ] 月額支払で要素が追加された時に発火
  setMultiMonthpicker()
})

export default { setDatepicker, setMonthpicker, setMultiMonthpicker }
