import WorkflowRepository from "./v1/workflowRepository"
import userRepository from "./v1/userRepository"
import constantRepository from "./v1/constantRepository"

const repositories = {
  workflow: WorkflowRepository,
  user: userRepository,
  constant: constantRepository
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
